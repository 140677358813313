import React, { useState, useEffect, useContext, createContext } from "react";
import { useProvideAuth } from "@hooks/use-auth";
import { User } from "@models/user.model";

export interface AuthenticationContext{
  user?: User;
  signin: (identifier: any) => Promise<User | undefined>,
  signout: () => void,
}

const defaultAuthenticationContext: AuthenticationContext = {
  signin: (_: any) => Promise.resolve(undefined),
  signout: () => {}
}

const authContext = createContext<AuthenticationContext>(defaultAuthenticationContext);
// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function ProvideAuth(props: any ) {
    //auth hookz
  const auth = useProvideAuth();
  return <authContext.Provider {...props} value={auth}></authContext.Provider>;
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => {
    return useContext(authContext);
  };