import React from "react";
import logo from "./logo.svg";
import "./App.scss";
import Navbar from "@components/Navbar";
import Gallery from "@components/gallery/Gallery";
//Moment locale import
import "moment/locale/fr";
import { Script } from "@models/script.model";
import { Language } from "@models/language.model";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { MissingRoute } from "@components/MissingRoute";
import { ProvideAuth } from "@contexts/ProvideAuth";
import { Toaster } from "react-hot-toast";

function App() {
  return (
    <ProvideAuth>
      <BrowserRouter>
        <div>
          <Toaster position="top-right" reverseOrder={false} />
        </div>
        <Navbar></Navbar>
        <Routes>
          <Route path="/" element={<Gallery></Gallery>} />
          <Route path="*" element={<MissingRoute />} />
        </Routes>
      </BrowserRouter>
    </ProvideAuth>
  );
}

export default App;
