import { Fragment, useState } from "react";
import { useAuth } from "@contexts/ProvideAuth";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toastError, toastSuccess } from "@libs/toasterService";
import { FaSpinner } from "react-icons/fa";

type LoginFormModalProps = {
  modalId: string;
};

const LoginFormModal = ({ modalId }: LoginFormModalProps) => {
  const [isLogging, setIsLogging] = useState<boolean>(false);
  const { signin } = useAuth();
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .email("Adresse email invalide")
        .required("l'email est obligatoire"),
      password: Yup.string().required("Le mot de passe est obligatoire"),
    }),
    onSubmit: async (values) => {
      setIsLogging(true);
      try {
        const user = await signin({
          email: values.email,
          password: values.password,
        });
        if (!user) {
          setIsLogging(false);
          toastError("Connexion non autorisée !");
        } else {
          toastSuccess("Connexion réussie !");
        }
      } catch (err) {
        setIsLogging(false);
        toastError("Informations de connexion invalides !");
        console.error(err);
        return;
      }
      setIsLogging(false);
      closeModal();
    },
  });

  const closeModal = () => {
    formik.resetForm({ values: { email: "", password: "" } });
    (document.querySelector(`#${modalId}`) as any).checked = false;
  };

  return (
    <Fragment>
      <input type="checkbox" id={modalId} className="modal-toggle" />
      <div className="modal">
        <div className="modal-box relative w-full bg-base-200 overflow-hidden">
          <label
            onClick={closeModal}
            className="btn btn-sm btn-circle absolute right-2 top-2 btn-primary text-white"
          >
            ✕
          </label>
          <h3 className="font-bold text-lg">Connexion</h3>
          <form onSubmit={formik.handleSubmit} className="overflow-auto h-4/5">
            <div className="form-control w-full">
              <label htmlFor="login" className="label">
                <span className="label-text">Email</span>
              </label>
              <input
                id="login"
                type="text"
                placeholder="Exemple: jean@hubi.ai"
                className={`input input-bordered w-full ${
                  formik.errors.email ? "border-red-500" : ""
                }`}
                onChange={formik.handleChange}
                value={formik.values.email}
                name="email"
                required
              />
            </div>
            <div className="form-control w-full">
              <label htmlFor="pass" className="label">
                <span className="label-text">Mot de passe</span>
              </label>
              <input
                type="password"
                id="pass"
                placeholder="********"
                name="password"
                className={`input input-bordered w-full ${
                  formik.errors.password ? "border-red-500" : ""
                }`}
                onChange={formik.handleChange}
                value={formik.values.password}
                required
              />
              {formik.errors.password && (
                <div className="error text-red-500">
                  {formik.errors.password}
                </div>
              )}
            </div>
            <div className="mt-8 flex justify-end">
              <button
                className="btn btn-primary capitalize btn-square text-white w-auto p-4"
                type="submit"
                disabled={!formik.dirty || isLogging}
              >
                <p className="text-center uppercase font-bold mr-2">
                  Connexion
                </p>
                {isLogging && <FaSpinner className="animate-spin text-white" />}
              </button>
            </div>
          </form>
        </div>
      </div>
    </Fragment>
  );
};

export default LoginFormModal;
