import { useEffect, useState } from "react";
import { Script } from "@models/script.model";
import ScriptCard from "@components/gallery/ScriptCard";
import { Filter } from "@models/filter.model";
import { Language } from "@models/language.model";
import { getScripts } from "@libs/scriptService";
import { useAuth } from "@contexts/ProvideAuth";
import MultiSelect from "@components/MultiSelect";
import { Option } from "@models/option.model";
import { Domain } from "@models/domain.model";
import { Tool } from "@models/tool.model";
import { Industry } from "@models/industry.model";
import { getDomains } from "@libs/domainService";
import { getIndustries } from "@libs/industryService";
import { getTools } from "@libs/toolService";
import { getLanguages } from "@libs/languageService";
import EditScriptModal from "./EditScriptModal";
import { useDebouncedCallback } from "use-debounce";
import ScriptDetailModal from "./ScriptDetailModal";
import { Customer } from "@models/customer.model";
import { getCustomers } from "@libs/customerService";
import { boolean } from "yup";

const Gallery = () => {
  const [displayedScripts, setDisplayedScripts] = useState<Script[]>([]);
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [domains, setDomains] = useState<Domain[]>([]);
  const [domainsOptions, setDomainsOptions] = useState<Option[]>([]);
  const [industries, setIndustries] = useState<Industry[]>([]);
  const [industriesOptions, setIndustriesOptions] = useState<Option[]>([]);
  const [isCustomersLoading, setIsCustomersLoading] = useState<boolean>(false);
  const [isDomainsOptionsLoading, setIsDomainsOptionsLoading] =
    useState<boolean>(false);
  const [isLanguagesOptionsLoading, setIsLanguagesOptionsLoading] =
    useState<boolean>(false);
  const [isIndustriesOptionsLoading, setIsIndustriesOptionsLoading] =
    useState<boolean>(false);
  const [isScriptsLoading, setIsScriptsLoading] = useState<boolean>(false);
  const [isToolsOptionsLoading, setIsToolsOptionsLoading] =
    useState<boolean>(false);
  const [languages, setLanguages] = useState<Language[]>([]);
  const [languagesOptions, setLanguagesOptions] = useState<Option[]>([]);
  const [scripts, setScripts] = useState<Script[]>([]);
  const [selectedScript, setSelectedScript] = useState<Script>({});
  const [tools, setTools] = useState<Tool[]>([]);
  const [toolsOptions, setToolsOptions] = useState<Option[]>([]);
  const [filter, setFilter] = useState<Filter>({
    domains: [],
    industries: [],
    languages: [],
    tools: [],
    name: "",
  });
  const { user } = useAuth();

  const debouncedNameInput = useDebouncedCallback(
    // function
    (name: string) => {
      setFilter({ ...filter, name: name });
    },
    // delay in ms
    1000
  );

  const newScript = (): Script => {
    return {
      partitionKey: "",
      rowKey: "",
      description: "",
      name: "",
      shortDescription: "",
      owner: user,
    };
  };

  /**
   * This method check URL query params and update filters and if necessary, open script modal.
   */
  const checkUrlQueryParams = () => {
    const queryParameters = new URLSearchParams(window.location.search);

    const selectedLanguages = filterAndMapQueryParams(
      languages,
      queryParameters.get("languages") || undefined
    );
    const selectedDomains = filterAndMapQueryParams(
      domains,
      queryParameters.get("domains") || undefined
    );
    const selectedIndustries = filterAndMapQueryParams(
      industries,
      queryParameters.get("industries") || undefined
    );
    const selectedTools = filterAndMapQueryParams(
      tools,
      queryParameters.get("tools") || undefined
    );
    const searchedName = queryParameters.get("name") || undefined;
    const searchedScript = queryParameters.get("script");
    setFilter({
      domains: selectedDomains,
      industries: selectedIndustries,
      languages: selectedLanguages,
      tools: selectedTools,
      name: searchedName,
    });

    if (searchedScript) {
      const script = displayedScripts.find((s) => s.rowKey === searchedScript);
      if (script) {
        setSelectedScript(script);
      }
    }
  };

  /**
   * this method filter data list and return item rowKey
   * @param list list to be filtered
   * @param queryParam list of parameters in URL
   * @returns for any list return selected rowKey (id)
   */
  const filterAndMapQueryParams = (
    list: Industry[] | Tool[] | Language[] | Domain[],
    queryParam?: string
  ) => {
    if (!queryParam) {
      return undefined;
    }
    const searchedValues = queryParam.split(",");
    return list
      .filter(
        (item) =>
          item.value &&
          searchedValues?.some(
            (searched) => searched.toLowerCase() === item.value?.toLowerCase()
          )
      )
      .map((item) => item.rowKey)
      .filter((rowKey) => typeof rowKey === "string") as string[];
  };

  const filterScripts = () => {
    let filteredList: Script[] = [...scripts];
    filteredList = filteredList.filter(
      (s) =>
        (!filter.name ||
          filter.name === "" ||
          (filter.name &&
            s.name?.toLowerCase().includes(filter.name.toLowerCase()))) &&
        (!filter.languages?.length ||
          (filter.languages &&
            filter.languages.some((language) => language === s.language))) &&
        (!filter.industries?.length ||
          (filter.industries &&
            filter.industries.some((industry) =>
              (s.industries as string[])?.some(
                (scriptIndustry) => scriptIndustry === industry
              )
            ))) &&
        (!filter.domains?.length ||
          (filter.domains &&
            filter.domains.some((domain) =>
              (s.domains as string[])?.some(
                (scriptDomain) => scriptDomain === domain
              )
            ))) &&
        (!filter.tools?.length ||
          (filter.tools &&
            filter.tools.some((tool) =>
              (s.tools || []).some((scriptTool) => scriptTool === tool)
            )))
    );
    setDisplayedScripts(filteredList);
  };

  const initCustomers = async () => {
    setIsCustomersLoading(true);
    await getCustomers().then((dbCustomers: Customer[]) => {
      setCustomers(dbCustomers);
      setIsCustomersLoading(false);
    });
  };

  const initDatas = async () => {
    setIsCustomersLoading(true);
    setIsDomainsOptionsLoading(true);
    setIsIndustriesOptionsLoading(true);
    setIsToolsOptionsLoading(true);
    setIsScriptsLoading(true);
    setIsLanguagesOptionsLoading(true);

    await Promise.all([
      initCustomers(),
      initDomainsOptions(),
      initIndustriesOptions(),
      initLanguageOptions(),
      initScripts(),
      initToolsOptions(),
    ]);
  };

  const initDomainsOptions = async () => {
    setIsDomainsOptionsLoading(true);
    await getDomains().then((dbDomains: Domain[]) => {
      setDomains(dbDomains);
      const domainsOptionsList = initListOptions(dbDomains);
      setDomainsOptions(domainsOptionsList);
      setIsDomainsOptionsLoading(false);
    });
  };

  const initIndustriesOptions = async () => {
    setIsIndustriesOptionsLoading(true);
    await getIndustries().then((dbIndustries: Industry[]) => {
      setIndustries(dbIndustries);
      const industriesOptionsList = initListOptions(dbIndustries);
      setIndustriesOptions(industriesOptionsList);
      setIsIndustriesOptionsLoading(false);
    });
  };

  const initLanguageOptions = async () => {
    setIsLanguagesOptionsLoading(true);
    await getLanguages().then((dbLanguages: Language[]) => {
      setLanguages(dbLanguages);
      const languagesOptions = initListOptions(dbLanguages);
      setLanguagesOptions(languagesOptions);
      setIsLanguagesOptionsLoading(false);
    });
  };

  const initListOptions = (list: any[]) => {
    const result: Option[] = [];
    list.forEach((i) => {
      const option: Option = {
        label: i.label as string,
        value: i.rowKey as string,
      };
      result.push(option);
    });
    return result;
  };

  const initToolsOptions = async () => {
    setIsToolsOptionsLoading(true);
    await getTools().then((dbTools: Tool[]) => {
      setTools(dbTools);
      const toolsOptionsList = initListOptions(dbTools);
      setToolsOptions(toolsOptionsList);
      setIsToolsOptionsLoading(false);
    });
  };

  const initScripts = async () => {
    setIsScriptsLoading(true);
    await getScripts().then((scripts: Script[]) => {
      setScripts(scripts);
      setDisplayedScripts(scripts);
      setIsScriptsLoading(false);
    });
  };

  const onCreateScriptModalFormSubmit = (script: Script) => {
    const copyScripts = [...scripts];
    copyScripts.push(script);
    setScripts(copyScripts);
  };

  const onCloseModal = () => {
    setSelectedScript({});
  };

  const onDomainsMultiselectValueChange = (selectedDomains: string[]) => {
    setFilter({ ...filter, domains: selectedDomains });
  };

  const onEditScriptModalFormSubmit = (script: Script) => {
    const copyScripts = [...scripts];
    const scriptIndex = copyScripts.findIndex(
      (s) => s.rowKey === script.rowKey
    );
    copyScripts[scriptIndex] = script;
    setScripts(copyScripts);
  };

  const onIndustriesMultiselectValueChange = (selectedIndustries: string[]) => {
    setFilter({ ...filter, industries: selectedIndustries });
  };

  const onLanguageMultiselectValueChange = (selectedLanguages: string[]) => {
    setFilter({ ...filter, languages: selectedLanguages });
  };

  const onToolsMultiselectValueChange = (selectedTools: string[]) => {
    setFilter({ ...filter, tools: selectedTools });
  };

  const onScriptCardClick = (script: Script) => {
    setSelectedScript(script);
  };

  useEffect(() => {
    initDatas();
  }, []);

  useEffect(() => {
    if (
      !isCustomersLoading &&
      !isDomainsOptionsLoading &&
      !isIndustriesOptionsLoading &&
      !isLanguagesOptionsLoading &&
      !isScriptsLoading &&
      !isToolsOptionsLoading
    ) {
      checkUrlQueryParams();
    }
  }, [
    isCustomersLoading,
    isDomainsOptionsLoading,
    isIndustriesOptionsLoading,
    isLanguagesOptionsLoading,
    isScriptsLoading,
    isToolsOptionsLoading,
  ]);

  useEffect(() => {
    filterScripts();
  }, [scripts]);

  useEffect(() => {
    filterScripts();
  }, [filter]);

  useEffect(() => {
    if (selectedScript.rowKey) {
      if (user && document.querySelector("#editScriptModal")) {
        (document.querySelector("#editScriptModal") as any).checked = true;
      } else if (document.querySelector("#scriptDetailModal")) {
        (document.querySelector("#scriptDetailModal") as any).checked = true;
      }
    }
  }, [selectedScript]);

  const $editScriptModal = !isDomainsOptionsLoading &&
    !isLanguagesOptionsLoading &&
    !isIndustriesOptionsLoading &&
    !isScriptsLoading &&
    !isToolsOptionsLoading && (
      <EditScriptModal
        modalId="editScriptModal"
        onScriptChanged={onEditScriptModalFormSubmit}
        title="Edition scénario"
        domainsOptions={domainsOptions}
        toolsOptions={toolsOptions}
        industriesOptions={industriesOptions}
        languagesOptions={languagesOptions}
        onCloseModal={() => onCloseModal()}
        script={selectedScript}
      />
    );

  const $scriptDetailModal = (
    <ScriptDetailModal
      modalId="scriptDetailModal"
      initScript={selectedScript}
      onCloseModal={() => onCloseModal()}
    />
  );

  return (
    <div className="flex bg-[#f8f8f8] p-4 h-full">
      {$editScriptModal}
      {$scriptDetailModal}
      <div className="container mx-auto py-8">
        <div className="mb-8">
          <div className="flex gap-2 flex-col md:flex-row">
            <div className="flex-initial w-full min-h-[250px]">
              <article className="prose lg:prose-xl w-full">
                <h1 className="text-primary">Automatisez sans limites</h1>
                <p>
                  La galerie de scénarios Hubi propose de nombreux scénarios
                  gratuits à intégrer en quelques clics pour améliorer
                  rapidement les capacités de vos chatbots Hubi et les connecter
                  à n'importe quel outil de votre SI.
                </p>
                <p className="font-bold text-secondary">
                  Cherchez. Customisez. Publiez. DONE! 
                </p>
              </article>
            </div>
            <iframe
              className="w-full ml-auto md:max-w-[720px]"
              src="https://www.youtube.com/embed/qzA64Jw9MbM"
              title="Video Hubi"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          </div>
        </div>
        <div className="mt-12 mb-12">
          <div className="flex gap-2 lg:gap-8 flex-wrap flex-col md:flex-row">
            <div className="form-control">
              <div className="relative">
                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                  <svg
                    aria-hidden="true"
                    className="w-5 h-5 text-gray-500 dark:text-gray-400"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                    ></path>
                  </svg>
                </div>
                <input
                  type="search"
                  id="default-search"
                  className="block w-full p-4 pl-10 text-sm rounded-lg shadow-lg focus:outline-none focus:ring focus:ring-secondary"
                  placeholder="Rechercher un scénario"
                  defaultValue={filter?.name}
                  onChange={(e) => debouncedNameInput(e.target.value)}
                />
              </div>
            </div>
            <div className="min-w-[200px] max-w-[215px]">
              {!isLanguagesOptionsLoading && languagesOptions.length && (
                <MultiSelect
                  initOptions={languagesOptions}
                  initSelectedOptions={filter.languages}
                  defaultLabel="Langues"
                  valueChange={onLanguageMultiselectValueChange}
                ></MultiSelect>
              )}
            </div>
            {!isDomainsOptionsLoading && domainsOptions.length && (
              <div className="min-w-[200px] max-w-[215px]">
                <MultiSelect
                  initOptions={domainsOptions}
                  initSelectedOptions={filter.domains}
                  defaultLabel="Domaines"
                  valueChange={onDomainsMultiselectValueChange}
                ></MultiSelect>
              </div>
            )}
            {!isIndustriesOptionsLoading && industriesOptions.length && (
              <div className="min-w-[200px] max-w-[215px]">
                <MultiSelect
                  initOptions={industriesOptions}
                  initSelectedOptions={filter.industries}
                  defaultLabel="Industries"
                  valueChange={onIndustriesMultiselectValueChange}
                ></MultiSelect>
              </div>
            )}
            {!isToolsOptionsLoading && toolsOptions.length && (
              <div className="min-w-[200px] max-w-[215px]">
                <MultiSelect
                  initOptions={toolsOptions}
                  initSelectedOptions={filter.tools}
                  defaultLabel="Outils"
                  valueChange={onToolsMultiselectValueChange}
                ></MultiSelect>
              </div>
            )}
            {user &&
              domainsOptions.length &&
              industriesOptions.length &&
              toolsOptions.length &&
              languagesOptions.length && (
                <div className="mb-4 ml-auto flex justify-end">
                  <label
                    htmlFor="createScriptModal"
                    className="btn btn-primary text-white"
                  >
                    Ajouter un scénario
                  </label>
                  <EditScriptModal
                    modalId="createScriptModal"
                    onScriptChanged={onCreateScriptModalFormSubmit}
                    title="Création scénario"
                    domainsOptions={domainsOptions}
                    toolsOptions={toolsOptions}
                    industriesOptions={industriesOptions}
                    languagesOptions={languagesOptions}
                    script={newScript()}
                    onCloseModal={() => onCloseModal()}
                  />
                </div>
              )}
          </div>
        </div>
        <div className="flex flex-wrap gap-8 mt-8">
          {displayedScripts.length > 0 &&
            displayedScripts?.map((displayedScript) => (
              <div
                key={displayedScript.rowKey}
                className="w-full md:w-[360px] h-[190px]"
                onClick={() => onScriptCardClick(displayedScript)}
              >
                <ScriptCard
                  initDomains={domains}
                  initCustomers={customers}
                  initIndustries={industries}
                  initLanguages={languages}
                  script={displayedScript}
                  initTools={tools}
                ></ScriptCard>
              </div>
            ))}
          {!displayedScripts?.length && (
            <p className="text-center w-full">
              Aucun scénario ne correspond à votre recherche.
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Gallery;
