import { Fragment, useEffect, useState } from "react";
import { Script } from "@models/script.model";
import { FaCopy, FaDownload, FaSpinner } from "react-icons/fa";
import { getDomains } from "@libs/domainService";
import { Domain } from "@models/domain.model";
import { getIndustries } from "@libs/industryService";
import { Industry } from "@models/industry.model";
import { getTools } from "@libs/toolService";
import { Tool } from "@models/tool.model";
import { download } from "@libs/fileService";
import { FileInput } from "./FileInput";
import ReactQuill from "react-quill";
import { toastSuccess } from "@libs/toasterService";

type ScriptModalProps = {
  initScript: Script;
  modalId: string;
  onCloseModal: Function;
};

const ScriptDetailModal = ({
  initScript,
  modalId,
  onCloseModal,
}: ScriptModalProps) => {
  const [script, setScript] = useState<Script>(initScript);
  const [isDomainsLoading, setIsDomainsLoading] = useState<boolean>(true);
  const [isFileDownloading, setIsFileDownloading] = useState<boolean>(false);
  const [isIndustriesLoading, setIsIndustriesLoading] = useState<boolean>(true);
  const [isToolsLoading, setIsToolsLoading] = useState<boolean>(true);

  const [domains, setDomains] = useState<Domain[]>([]);
  const [industries, setIndustries] = useState<Industry[]>([]);
  const [tools, setTools] = useState<Tool[]>([]);

  const quillOptions = {
    readOnly: true,
    modules: { toolbar: false },
  };

  const closeModal = () => {
    (document.querySelector(`#${modalId}`) as any).checked = false;
    onCloseModal();
  };

  const initDomains = () => {
    getDomains().then((dbDomains: Domain[]) => {
      setDomains(dbDomains);
      setIsDomainsLoading(false);
    });
  };

  const initIndustries = () => {
    getIndustries().then((dbIndustries: Industry[]) => {
      setIndustries(dbIndustries);
      setIsIndustriesLoading(false);
    });
  };

  const initTools = () => {
    getTools().then((dbTools: Domain[]) => {
      setTools(dbTools);
      setIsToolsLoading(false);
    });
  };

  const onClipboardiconClick = () => {
    if (script.rowKey) {
      const url = new URL(window.location.href);
      const clearedUrl = `${url.origin}?script=${script.rowKey}`;
      navigator.clipboard.writeText(clearedUrl);
      toastSuccess("Lien du scénario copié !");
    }
  };

  useEffect(() => {
    setScript(initScript);
  }, [initScript]);

  useEffect(() => {
    initDomains();
    initIndustries();
    initTools();
  }, []);
  return (
    <Fragment>
      <input type="checkbox" id={modalId} className="modal-toggle" />
      <div className="modal h-full">
        <div className="modal-box relative h-full w-full overflow-hidden max-w-sm md:max-w-xl lg:max-w-5xl">
          <label
            onClick={() => closeModal()}
            className="btn btn-sm btn-circle absolute right-2 top-2 btn-primary text-white"
          >
            ✕
          </label>
          <h1 className="font-bold text-lg text-primary flex items-center gap-4">
            {script.name}
            {script.rowKey && (
              <div
                onClick={() => onClipboardiconClick()}
                className="badge badge-secondary text-white w-auto px-2 py-1 hover:cursor-pointer tooltip tooltip-right"
                data-tip="copier le lien"
              >
                <FaCopy className="text-[12px]" />
              </div>
            )}
          </h1>
          <div className="overflow-auto h-[95%] relative flex flex-col gap-8">
            <div>
              <p>{script.shortDescription}</p>
            </div>
            <div>
              <h3 className="uppercase font-bold text-primary">
                Description :
              </h3>
              {script.description && (
                <div>
                  <ReactQuill
                    value={script.description}
                    {...quillOptions}
                    className="quill-no-border"
                  />
                </div>
              )}
            </div>
            {script.deployment && (
              <div>
                <h3 className="uppercase font-bold text-primary">
                  Déploiement :
                </h3>
                <div>
                  <ReactQuill
                    value={script.deployment}
                    {...quillOptions}
                    className="quill-no-border"
                  />
                </div>
              </div>
            )}
            {!isDomainsLoading &&
              script.domains &&
              script.domains.length > 0 && (
                <div>
                  <h3 className="uppercase font-bold text-primary mb-2">
                    Domaines :
                  </h3>
                  <div className="flex gap-2">
                    {script.domains?.map((domainRowKey) => (
                      <div
                        key={domainRowKey}
                        className="badge badge-secondary p-4 text-white uppercase font-bold"
                      >
                        {domains.find((d) => d.rowKey === domainRowKey)?.value}
                      </div>
                    ))}
                  </div>
                </div>
              )}
            {!isIndustriesLoading &&
              script.industries &&
              script.industries.length > 0 && (
                <div>
                  <h3 className="uppercase font-bold text-primary mb-2">
                    Industries :
                  </h3>
                  <div className="flex gap-2">
                    {script.industries?.map((industryRowKey) => (
                      <div
                        key={industryRowKey}
                        className="badge badge-secondary p-4 text-white uppercase font-bold"
                      >
                        {
                          industries.find((i) => i.rowKey === industryRowKey)
                            ?.value
                        }
                      </div>
                    ))}
                  </div>
                </div>
              )}
            {!isToolsLoading && script.tools && script.tools.length > 0 && (
              <div>
                <h3 className="uppercase font-bold text-primary mb-2">
                  Outils :
                </h3>
                <div className="flex gap-2">
                  {script.tools?.map((toolRowKey) => (
                    <div
                      key={toolRowKey}
                      className="badge badge-secondary p-4 text-white uppercase font-bold"
                    >
                      {tools.find((t) => t.rowKey === toolRowKey)?.value}
                    </div>
                  ))}
                </div>
              </div>
            )}
            <div>
              <h3 className="uppercase font-bold text-primary mb-2">
                Documents :
              </h3>
              {script.fileNames &&
                script.fileNames?.map((fileName, index) => (
                  <div key={index} className="form-control w-full mb-4 p-4">
                    <FileInput
                      canRemoveFile={false}
                      filePath={
                        script.rowKey ? `script-files/${script.rowKey}` : ""
                      }
                      fileName={fileName}
                    />
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ScriptDetailModal;
