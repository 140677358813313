import { getEntities } from "@libs/azureDataTables";
import { Language } from "@models/language.model";

export const getLanguages = async () => {
  const result = await getEntities("languages");
  const languages = [];

  for await (const entity of result) {
    const language: Language = entity;
    languages.push(language);
  }
  return languages;
};
