import { toast } from "react-hot-toast";
import { FaCheckCircle, FaTimes, FaTimesCircle } from "react-icons/fa";

export const toastError = async (msg?: string, d?: number) => {
  toast.custom(
    (t) => (
      <div
        className={`${
          t.visible ? "animate-enter" : "animate-leave"
        } max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
      >
        <div className="flex-1 w-0 p-4">
          <div className="flex items-center">
            <div className="flex-shrink-0 pt-0.5">
              <img
                className="h-10"
                src="../../../img/logo-pouple-hubi.png"
                alt="hubi logo"
              />
            </div>
            <div className="ml-3 flex-1">
              <p className="flex items-center text-sm font-medium text-gray-900">
                <FaTimesCircle className="mr-2 text-error" />{" "}
                {msg ? msg : "Une erreur est survenue !"}
              </p>
            </div>
          </div>
        </div>
      </div>
    ),
    {
      duration: d ? d : 3000,
    }
  );
};

export const toastSuccess = async (msg?: string, d?: number) => {
  toast.custom(
    (t) => (
      <div
        className={`${
          t.visible ? "animate-enter" : "animate-leave"
        } max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
      >
        <div className="flex-1 w-0 p-4">
          <div className="flex items-center">
            <div className="flex-shrink-0 pt-0.5">
              <img
                className="h-10"
                src="../../../img/logo-pouple-hubi.png"
                alt="hubi logo"
              />
            </div>
            <div className="ml-3 flex-1">
              <p className="flex items-center text-sm font-medium text-gray-900">
                <FaCheckCircle className="mr-2 text-success" />{" "}
                {msg ? msg : "Opération réussie !"}
              </p>
            </div>
          </div>
        </div>
      </div>
    ),
    {
      duration: d ? d : 3000,
    }
  );
};
