import { BlobServiceClient } from "@azure/storage-blob";

const sasToken: string = process.env
  .REACT_APP_AZ_BLOB_STORAGE_SAS_TOKEN as string;
const storageAccountName: string = process.env
  .REACT_APP_AZ_DATA_TABLE_ACCOUNT as string;

export async function createFile(filePath: string, file: any) {
  console.log("start create file");
  // Create the BlobServiceClient object which will be used to create a container client
  const blobService = new BlobServiceClient(
    `https://${storageAccountName}.blob.core.windows.net/?${sasToken}`
  );
  const containerClient = blobService.getContainerClient(`${filePath}`);

  const blobName = file.name;

  const blockBlobClient = containerClient.getBlockBlobClient(file.name);

  console.log("\nUploading to Azure storage as blob:\n\t", blobName);
  // Upload the file to Azure Blob Storage
  const response = await blockBlobClient.uploadData(file, {
    blobHTTPHeaders: {
      blobContentType: file.mimetype,
    },
  });

  return response;
}

export async function download(filePath: string, fileName: string) {
  // Create the BlobServiceClient object which will be used to create a container client
  const blobServiceClient = new BlobServiceClient(
    `https://${storageAccountName}.blob.core.windows.net/?${sasToken}`
  );
  const containerClient = blobServiceClient.getContainerClient(`${filePath}`);
  const blobName = fileName;
  const blobClient = containerClient.getBlobClient(blobName);

  // Get blob content from position 0 to the end
  // In browsers, get downloaded data by accessing downloadBlockBlobResponse.blobBody
  const downloadBlockBlobResponse = await blobClient.download();
  const downloaded = await blobToString(
    await downloadBlockBlobResponse.blobBody,
    fileName
  );
  console.log("Downloaded blob content", downloaded);
}

// [Browsers only] A helper method used to convert a browser Blob into string.
async function blobToString(blob: any, fileName: string) {
  const blobUrl = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = blobUrl;
  link.download = fileName;
  link.click();
}

export const removeExcedentFiles = async (
  keepedFiles: string[],
  containerName: string,
  directoryName: string
) => {
  // Create the BlobServiceClient object which will be used to create a container client
  const blobServiceClient = new BlobServiceClient(
    `https://${storageAccountName}.blob.core.windows.net/?${sasToken}`
  );
  const containerClient = blobServiceClient.getContainerClient(containerName);

  for await (const blob of containerClient.listBlobsFlat()) {
    const splitedBlobName = blob.name.split("/");
    const fileName = splitedBlobName[1];
    const blobDirectory = splitedBlobName[0];

    if (
      blobDirectory === directoryName &&
      !keepedFiles.some((f) => f === fileName)
    ) {
      removeFile(containerName, blob.name);
    }
  }
};

const removeFile = async (path: string, fileName: string) => {
  const blobServiceClient = new BlobServiceClient(
    `https://${storageAccountName}.blob.core.windows.net/?${sasToken}`
  );
  const containerClient = blobServiceClient.getContainerClient(path);
  const blobFile = containerClient.getBlockBlobClient(fileName);
  await blobFile.delete();
};
