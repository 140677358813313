import { TableClient } from "@azure/data-tables";
const {
  TableServiceClient,
  AzureSASCredential,
} = require("@azure/data-tables");

const sasToken = process.env.REACT_APP_AZ_DATA_TABLE_SAS_TOKEN;
const account = process.env.REACT_APP_AZ_DATA_TABLE_ACCOUNT;

const tableServiceClient = new TableServiceClient(
  `https://${account}.table.core.windows.net`,
  new AzureSASCredential(sasToken)
);

/**
 * get entity from table <tableName>
 * @param tableName name of table
 * @param rowKey rowKey of entity
 * @param partitionKey partitionKey of entity
 */
export const getEntity = async (tableName: string) => {
  const tableClient = new TableClient(
    `https://${account}.table.core.windows.net`,
    tableName,
    new AzureSASCredential(sasToken)
  );
  let entitiesIter = await tableClient.listEntities();
  let i = 1;
  for await (const entity of entitiesIter) {
    console.log(entity);
    console.log(
      `Entity${i}: PartitionKey: ${entity.partitionKey} RowKey: ${entity.rowKey}`
    );
    i++;
  }
};

/**
 * get all entities from table <tableName>
 * @param tableName name of table
 */
export const getEntities = async (tableName: string) => {
  const tableClient = new TableClient(
    `https://${account}.table.core.windows.net`,
    tableName,
    new AzureSASCredential(sasToken)
  );
  let entitiesIter = tableClient.listEntities();
  return entitiesIter;
};

/**
 *
 * Create an entity into table <tableName>
 * @param tableName name of table
 * @param entity entity to save
 *
 */
export const createEntity = async (tableName: string, entity: any) => {
  /**
   * 
   it is commented because if the table already exist, tableServiceClient return conflict error...
    //Use createTable method because tableServiceClient not provide a find method
    await createTable(tableName);
  */
  const tableClient = new TableClient(
    `https://${account}.table.core.windows.net`,
    tableName,
    new AzureSASCredential(sasToken)
  );

  return tableClient.createEntity(entity);
};

/**
 * list all tables on azure data table
 */
export const listTables = async () => {
  const entitiesIter = await tableServiceClient.listTables();
  let i = 1;
  for await (const table of entitiesIter) {
    console.log(`Table${i}: ${table.name}`);
    i++;
  }
};
/**
 * this method update entity
 * @param entity entity to update
 * @param tableName table containing entity to update
 */
export const patchEntity = async (tableName: string, entity: any) => {
  const tableClient = new TableClient(
    `https://${account}.table.core.windows.net`,
    tableName,
    new AzureSASCredential(sasToken)
  );
  //use replace mode cause 'Merge' is blocked by CORS
  return tableClient.updateEntity(entity, "Replace");
};

/**
 * Create table <tableName>, If the table <tableName> already exists, createTable doesn't throw
 *
 * @param tableName name of table
 * Warning this method return an error if table already exist but its not an exception, so execution will not stopped
 */
const createTable = (tableName: string): Promise<any> => {
  return tableServiceClient.createTable(tableName);
};
