import { getEntities } from "@libs/azureDataTables";
import { Industry } from "@models/industry.model";

export const getIndustries = async () => {
  const result = await getEntities("industries");
  const industries = [];

  for await (const entity of result) {
    const industry: Industry = entity;
    industries.push(industry);
  }
  return industries;
};
