import { Language } from "@models/language.model";
import { Script } from "@models/script.model";
import moment from "moment";
import { useState } from "react";
import { FaPen, FaSearch } from "react-icons/fa";
import EditScriptModal from "@components/gallery/EditScriptModal";
import ScriptDetailModal from "@components/gallery/ScriptDetailModal";
import { useNavigate } from "react-router-dom";
import { useAuth } from "@contexts/ProvideAuth";
import { Domain } from "@models/domain.model";
import { Industry } from "@models/industry.model";
import { Tool } from "@models/tool.model";
import { Customer } from "@models/customer.model";
import { getCustomers } from "@libs/customerService";

type ScriptCardProps = {
  initDomains: Domain[];
  initCustomers: Customer[];
  initIndustries: Industry[];
  initLanguages: Language[];
  initTools: Tool[];
  script: Script;
};

const ScriptCard = ({
  initDomains,
  initCustomers,
  initIndustries,
  initLanguages,
  script,
  initTools,
}: ScriptCardProps) => {
  const [languages, setLanguages] = useState<Language[]>(initLanguages);
  const [domains, setDomains] = useState<Domain[]>(initDomains);
  const [customers, setCustomers] = useState<Customer[]>(initCustomers);
  const [industries, setIndustries] = useState<Industry[]>(initIndustries);
  const [tools, setTools] = useState<Tool[]>(initTools);
  const { user } = useAuth();

  const getFlag = (languageRowKey: string) => {
    return `../../../img/${
      languages.find((l) => l.rowKey === languageRowKey)?.filename
    }`;
  };

  const getScriptCustomer = () => {
    let customer = "N/A";
    if (script.owner) {
      const index = customers.findIndex((c) => c.rowKey === script.owner);
      if (index !== -1) {
        customer = customers[index].value || "N/A";
      }
    }
    return customer;
  };

  moment.locale("fr-FR");
  getScriptCustomer();

  return (
    <div className="h-full card bg-white rounded-md border-grey border-b-4 border-b-primary cursor-pointer shadow-xl hover:shadow-md shadow-grey hover:shadow-primary">
      <div className="card-body relative">
        <div className="text-left w-full">
          <h2 className="card-title text-primary">
            <span className="line-clamp-2">{script.name}</span>
          </h2>
          <div className="flex absolute top-2 right-2">
            {script.language && (
              <img
                className="rounded-full w-5 h-5"
                src={getFlag(script.language)}
                alt="language flag"
              />
            )}
          </div>
        </div>
        <div className="flex justify-between">
          <p className="text-xs text-neutral italic truncate">
            Modifié : {moment(script.lastUpdate).format("L")}
          </p>
        </div>
        {script.shortDescription && (
          <p className="line-clamp-2 max-h-[47px] ">
            {script.shortDescription}
          </p>
        )}
      </div>
      <figure className="absolute bottom-0 right-0">
        <img
          className="max-h-[145px]"
          src="../../../img/bg-script-card.png"
          alt="card background"
        />
      </figure>
    </div>
  );
};

export default ScriptCard;
