import { Fragment, useEffect, useState } from "react";
import { Script } from "@models/script.model";
import Multiselect from "@components/MultiSelect";
import { Option } from "@models/option.model";
import { createScript, formatScript, patchScript } from "@libs/scriptService";
import { createFile, removeExcedentFiles } from "@libs/fileService";
import { FileInput } from "./FileInput";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toastError, toastSuccess } from "@libs/toasterService";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { FaSpinner } from "react-icons/fa";

const { v4 } = require("uuid");

type ScriptModalProps = {
  domainsOptions: Option[];
  industriesOptions: Option[];
  script: Script;
  languagesOptions: Option[];
  modalId: string;
  onCloseModal: Function;
  title: string;
  toolsOptions: Option[];
  onScriptChanged: (script: Script) => void;
};

const EditScriptModal = ({
  domainsOptions,
  industriesOptions,
  languagesOptions,
  script,
  modalId,
  onCloseModal,
  title,
  toolsOptions,
  onScriptChanged,
}: ScriptModalProps) => {
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isScriptInitializing, setIsScriptInitializing] =
    useState<boolean>(false);
  const isEdition = script.partitionKey && script.rowKey;

  const closeModal = () => {
    formik.resetForm({
      values: {
        deployment: script.deployment ? script.deployment : "",
        description: script.description ? script.description : "",
        domains: script.domains ? script.domains : [],
        fileNames: script.fileNames ? script.fileNames : [],
        industries: script.industries ? script.industries : [],
        language: script.language ? script.language : "",
        name: script.name ? script.name : "",
        shortDescription: script.shortDescription
          ? script.shortDescription
          : "",
        tools: script.tools ? script.tools : [],
      },
    });
    (document.querySelector(`#${modalId}`) as any).checked = false;
    onCloseModal();
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      deployment: script.deployment ? script.deployment : "",
      description: script.description ? script.description : "",
      domains: script.domains ? script.domains : [],
      fileNames: script.fileNames ? script.fileNames : [],
      industries: script.industries ? script.industries : [],
      language: script.language ? script.language : "",
      name: script.name || " ",
      shortDescription: script.shortDescription || " ",
      tools: script.tools ? script.tools : [],
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required("le nom est obligatoire"),
      shortDescription: Yup.string().required(
        "La présentation est obligatoire"
      ),
    }),
    onSubmit: async (values) => {
      setIsLoading(true);
      const scriptCopy: any = {
        ...script,
        description: values.description,
        deployment: values.deployment,
        domains: values.domains.join(","),
        fileNames: values.fileNames.join(","),
        industries: values.industries.join(","),
        language: Array.isArray(values.language)
          ? values.language[0]
          : values.language,
        name: values.name,
        shortDescription: values.shortDescription,
        tools: values.tools.join(","),
      };
      if (!isEdition) {
        scriptCopy.rowKey = v4();
        scriptCopy.partitionKey = "scénario";
        scriptCopy.owner = script.owner?.clientId;
      }
      //upload file on azure file storage
      if (selectedFiles) {
        await Promise.all(
          selectedFiles.map(async (f) => {
            try {
              await createFile(`script-files/${scriptCopy.rowKey}`, f);
            } catch (err) {
              toastError("Erreur lors de l'enregistrement du fichier !");
              console.error(err);
              setIsLoading(false);
              return;
            }
          })
        );
      }
      await removeExcedentFiles(
        scriptCopy.fileNames.split(","),
        "script-files",
        scriptCopy.rowKey
      );
      if (isEdition) {
        try {
          await patchScript(scriptCopy);
          toastSuccess("Mise à jour du scénario terminée !");
          onScriptChanged(formatScript(scriptCopy));
          setIsLoading(false);
          closeModal();
        } catch (err) {
          console.error(err);
          toastError("Erreur lors de la mise à jour du scénario !");
          setIsLoading(false);
        }
      } else {
        try {
          await createScript(scriptCopy);
          toastSuccess("Création du scénario terminée !");
          onScriptChanged(formatScript(scriptCopy));
          setIsLoading(false);
          closeModal();
        } catch (err) {
          console.error(err);
          toastError("Erreur lors de la création du scénario !");
          setIsLoading(false);
        }
      }
    },
  });

  const handleFileChange = (file: File | null) => {
    if (file) {
      const selectedFilesCopy = [...selectedFiles];
      selectedFilesCopy?.push(file);
      setSelectedFiles([...selectedFilesCopy]);
    }
  };

  const handleRemoveFileButtonClick = (fileName: string) => {
    if (selectedFiles) {
      const selectedFilesCopy = [...selectedFiles];
      const index = selectedFilesCopy.findIndex((f) => f.name === fileName);
      if (index !== -1) {
        selectedFilesCopy.splice(index, 1);
        setSelectedFiles([...selectedFilesCopy]);
      }
    }
    const fileNames = [...formik.values.fileNames];
    const index = fileNames.findIndex((f) => f === fileName);
    if (index !== -1) {
      fileNames.splice(index, 1);
      formik.setFieldValue("fileNames", [...fileNames]);
    }
  };

  const onDescriptionValueChange = (description: string) => {
    formik.setFieldValue("description", description);
  };

  const onDeploymentValueChange = (deployment: string) => {
    formik.setFieldValue("deployment", deployment);
  };

  const onDomainMultiselectValueChange = (selectedDomains: string[]) => {
    formik.setFieldValue("domains", selectedDomains);
  };

  const onIndustryMultiselectValueChange = (selectedIndustries: string[]) => {
    formik.setFieldValue("industries", selectedIndustries);
  };

  const onToolMultiselectValueChange = (selectedTools: string[]) => {
    formik.setFieldValue("tools", selectedTools);
  };

  const onLanguageMultiselectValueChange = (selectedLanguage: string) => {
    formik.setFieldValue("language", selectedLanguage);
  };

  useEffect(() => {
    formik.resetForm({
      values: {
        deployment: script.deployment ? script.deployment : "",
        description: script.description ? script.description : "",
        domains: script.domains ? script.domains : [],
        fileNames: script.fileNames ? script.fileNames : [],
        industries: script.industries ? script.industries : [],
        language: script.language ? script.language : "",
        name: script.name || "",
        shortDescription: script.shortDescription || "",
        tools: script.tools ? script.tools : [],
      },
    });
    setIsScriptInitializing(false);
  }, [script]);

  useEffect(() => {
    selectedFiles?.forEach((f) => {
      const index = formik.values.fileNames.findIndex((fn) => fn === f.name);
      if (index === -1) {
        formik.setFieldValue("fileNames", [...formik.values.fileNames, f.name]);
      }
    });
  }, [selectedFiles]);

  return (
    <Fragment>
      <input type="checkbox" id={modalId} className="modal-toggle" />
      <div className="modal h-full">
        <div className="modal-box relative h-full w-full overflow-hidden max-w-5xl ">
          <label
            onClick={() => closeModal()}
            className="btn btn-sm btn-circle absolute right-2 top-2 btn-primary text-white"
          >
            ✕
          </label>
          <h3 className="font-bold text-lg text-primary">{title}</h3>
          {!isScriptInitializing && (
            <form
              onSubmit={formik.handleSubmit}
              className="overflow-auto h-4/5 p-2"
            >
              <div className="form-control w-full">
                <label className="label">
                  <span className="label-text">Nom du scénario</span>
                </label>
                <input
                  type="text"
                  placeholder="scénario d'exemple"
                  className={`input input-bordered w-full ${
                    formik.errors.name ? "border-red-500" : ""
                  }`}
                  onChange={formik.handleChange}
                  value={formik.values.name}
                  name="name"
                />
                {formik.errors.name && (
                  <div className="error text-red-500">{formik.errors.name}</div>
                )}
              </div>
              <div className="form-control w-full">
                <label className="label">
                  <span className="label-text">Présentation</span>
                </label>
                <input
                  type="text"
                  placeholder="brève description du scénario..."
                  className={`input input-bordered w-full ${
                    formik.errors.shortDescription ? "border-red-500" : ""
                  }`}
                  onChange={formik.handleChange}
                  value={formik.values.shortDescription}
                  name="shortDescription"
                  required
                />
                {formik.errors.shortDescription && (
                  <div className="error text-red-500">
                    {formik.errors.shortDescription}
                  </div>
                )}
              </div>
              <div className="form-control w-full">
                <label className="label">
                  <span className="label-text">Description du scénario</span>
                </label>
                <div>
                  <ReactQuill
                    theme="snow"
                    value={formik.values.description}
                    onChange={onDescriptionValueChange}
                  />
                </div>
                {formik.errors.description && (
                  <div className="error text-red-500">
                    {formik.errors.description}
                  </div>
                )}
              </div>
              <div className="form-control w-full mb-4">
                <label className="label">
                  <span className="label-text">Déploiement</span>
                </label>
                <div>
                  <ReactQuill
                    theme="snow"
                    value={formik.values.deployment}
                    onChange={onDeploymentValueChange}
                  />
                </div>
                {formik.errors.deployment && (
                  <div className="error text-red-500">
                    {formik.errors.deployment}
                  </div>
                )}
              </div>
              <div className="flex gap-8 flex-col md:flex-row mb-4">
                <div className="form-control w-full max-w-[215px]">
                  <Multiselect
                    initOptions={domainsOptions}
                    defaultLabel="Domaines"
                    initSelectedOptions={formik.values.domains as string[]}
                    valueChange={onDomainMultiselectValueChange}
                  ></Multiselect>
                </div>
                <div className="form-control w-full max-w-[215px]">
                  <Multiselect
                    initOptions={industriesOptions}
                    defaultLabel="Industries"
                    initSelectedOptions={formik.values.industries as string[]}
                    valueChange={onIndustryMultiselectValueChange}
                  ></Multiselect>
                </div>
                <div className="form-control w-full max-w-[215px]">
                  <Multiselect
                    initOptions={toolsOptions}
                    defaultLabel="Outils"
                    initSelectedOptions={formik.values.tools as string[]}
                    valueChange={onToolMultiselectValueChange}
                  ></Multiselect>
                </div>
                <div className="form-control w-full max-w-[215px]">
                  <Multiselect
                    initOptions={languagesOptions}
                    defaultLabel="Langue"
                    initSelectedOptions={
                      formik.values.language
                        ? [formik.values.language as string]
                        : []
                    }
                    isUniqueSelection={true}
                    valueChange={onLanguageMultiselectValueChange}
                  ></Multiselect>
                </div>
              </div>
              {formik.values.fileNames?.length > 0 &&
                formik.values.fileNames?.map((fileName, index) => (
                  <div key={index} className="form-control w-full mb-4">
                    <FileInput
                      canRemoveFile={true}
                      onFileChange={handleFileChange}
                      onRemoveFileButtonClick={() =>
                        handleRemoveFileButtonClick(fileName)
                      }
                      filePath={
                        script.rowKey ? `script-files/${script.rowKey}` : ""
                      }
                      fileName={fileName}
                    />
                  </div>
                ))}
              <div className="form-control w-full">
                <FileInput
                  onFileChange={handleFileChange}
                  onRemoveFileButtonClick={handleRemoveFileButtonClick}
                />
              </div>
              <div className="modal-action max-w-md absolute bottom-6 right-6">
                <button
                  className="btn btn-primary capitalize btn-square text-white w-auto p-4"
                  type="submit"
                  disabled={!formik.dirty || isLoading}
                >
                  Enregistrer
                  {isLoading && (
                    <FaSpinner className="ml-4 animate-spin text-white" />
                  )}
                </button>
              </div>
            </form>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default EditScriptModal;
