import { getEntities } from "@libs/azureDataTables";
import { Domain } from "@models/domain.model";

export const getDomains = async () => {
  const result = await getEntities("domains");
  const domains = [];

  for await (const entity of result) {
    const domain: Domain = entity;
    domains.push(domain);
  }
  return domains;
};
