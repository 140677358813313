import { createEntity, getEntities, patchEntity } from "@libs/azureDataTables";
import { Script } from "@models/script.model";

export const createScript = async (script: Script) => {
  return await createEntity("scripts", script);
};

export const getScripts = async () => {
  const result = await getEntities("scripts");
  const scripts = [];

  for await (const entity of result) {
    //format object properly because on dataTable we can't have arrays
    const script: Script = formatScript(entity);
    scripts.push(script);
  }
  return scripts;
};

export const patchScript = async (script: any) => {
  return patchEntity("scripts", script);
};

export const formatScript = (entity: any): Script => {
  return {
    ...entity,
    domains: entity.domains ? (entity.domains as string)?.split(",") : [],
    industries: entity.industries
      ? (entity.industries as string)?.split(",")
      : [],
    tools: entity.tools ? (entity.tools as string)?.split(",") : [],
    fileNames: entity.fileNames ? (entity.fileNames as string)?.split(",") : [],
    lastUpdate: entity.timestamp,
  };
};
