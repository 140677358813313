import { Option } from "@models/option.model";
import { useEffect, useState } from "react";

type MultiSelectProps = {
  defaultLabel?: string;
  initOptions: Option[];
  initSelectedOptions?: string[];
  isUniqueSelection?: boolean;
  valueChange: Function;
};

const Multiselect = ({
  defaultLabel,
  initOptions,
  initSelectedOptions,
  valueChange,
  isUniqueSelection,
}: MultiSelectProps) => {
  const [options, setOptions] = useState<Option[]>(initOptions);
  const [selectedOptions, setSelectedOptions] = useState<string[]>(
    initSelectedOptions ? initSelectedOptions : []
  );
  const [label, setLabel] = useState<string>(
    defaultLabel ? defaultLabel : "Click Me !"
  );

  const onOptionClick = (option: Option) => {
    let selectedOptionCopy = [];
    if (isUniqueSelection) {
      selectedOptionCopy = [option.value];
    } else {
      selectedOptionCopy = [...selectedOptions];
      const optionIndex = selectedOptionCopy.findIndex(
        (so) => so === option.value
      );
      if (optionIndex !== -1) {
        selectedOptionCopy.splice(optionIndex, 1);
      } else {
        selectedOptionCopy.push(option.value);
      }
    }
    setSelectedOptions(selectedOptionCopy);
    valueChange(selectedOptionCopy);
  };

  useEffect(() => {
    setSelectedOptions(initSelectedOptions || []);
  }, [initSelectedOptions]);

  return (
    <div className="w-full mb-2 dropdown">
      <label tabIndex={0} className="w-full btn btn-outline btn-secondary">
        <p className="truncate w-4/5 text-left normal-case">
          {selectedOptions.length
            ? selectedOptions.map((so, index) => (
                <span key={index} className="mr-2">
                  {`${options.find((o) => o.value == so)?.label}${
                    index !== selectedOptions.length - 1 ? "," : ""
                  }`}
                </span>
              ))
            : label}
        </p>
        <svg
          className="w-4 h-4 ml-2 flex-1"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M19 9l-7 7-7-7"
          ></path>
        </svg>
      </label>
      <ul
        tabIndex={0}
        className="dropdown-content menu p-2 bg-base-100 w-full rounded-box shadow"
      >
        {options.map((o) => (
          <li
            onClick={() => onOptionClick(o)}
            key={o.value}
            className={`'cursor-pointer' ${
              selectedOptions.find((so) => so === o.value)
                ? "border-l-4 border-secondary border-radius-0 rounded-none italic opacity-75"
                : ""
            }`}
          >
            <p className="capitalize">{o.label}</p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Multiselect;
