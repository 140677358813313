import { useEffect, useState } from "react";
import { FaFile, FaSpinner, FaTrash } from "react-icons/fa";
import { download } from "@libs/fileService";

export interface FileInputProps extends React.HTMLProps<HTMLInputElement> {
  canRemoveFile?: boolean;
  filePath?: string;
  fileName?: string;
  onFileChange?: (file: File | null) => void;
  onRemoveFileButtonClick?: (fileName: string) => void;
}

export const FileInput = (props: FileInputProps) => {
  const [file, setFile] = useState<File | null>(null);
  const [isFileBeingDragged, setIsFileBeingDragged] = useState<boolean>(false);
  const [isFileDownloading, setIsFileDownloading] = useState<boolean>(false);

  useEffect(() => {
    if (props.onFileChange) {
      props.onFileChange(file);
    }
  }, [file]);

  const defaultClassName =
    "flex flex-col w-full border-opacity-50 border-dashed border-2 border-primary p-4 rounded-lg";
  const className =
    "flex flex-col w-full border-opacity-50 border-dashed border-2 border-secondary p-4 rounded-2xl bg-base-300";

  const onFileNameClick = async () => {
    if (!isFileDownloading) {
      setIsFileDownloading(true);
      if (props.filePath && props.fileName) {
        await download(props.filePath, props.fileName);
        setIsFileDownloading(false);
      }
    }
  };

  const onFileDragEnter = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.types[0] === "Files") {
      setIsFileBeingDragged(true);
    }
  };

  const onFileDragOver = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const onFileDrop = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.files.length) {
      setFile(e.dataTransfer.files[0]);
    }
    setIsFileBeingDragged(false);
  };
  const onFileInputChange = (event: any) => {
    if (event.target.files[0]) {
      const file = event.target.files[0];
      setFile(file);
    }
  };

  const onRemoveFileButtonClick = (event: any) => {
    if (props.onRemoveFileButtonClick && props.fileName) {
      props.onRemoveFileButtonClick(props.fileName);
    }
  };

  const $inputBlock = !props.fileName && (
    <>
      <div className="text-center text-2xl">
        Glissez-déposez votre fichier ici
      </div>
      <div className="divider my-9">OU</div>
      <div className="grid place-items-center">
        <label className="btn btn-primary btn-outline">
          Chercher un fichier...
          <input className="hidden" type="file" onChange={onFileInputChange} />
        </label>
      </div>
    </>
  );

  const $fileBlock = props.fileName && (
    <div className="relative flex justify-center items-center gap-2 p-2">
      <FaFile className="text-secondary" />
      <p
        className=" truncate text-center uppercase font-bold text-primary hover:cursor-pointer hover:text-secondary"
        onClick={onFileNameClick}
      >
        {props.fileName}
      </p>
      {isFileDownloading && (
        <FaSpinner className="animate-spin text-secondary" />
      )}
      {props.canRemoveFile && (
        <button
          type="button"
          className="ml-auto btn btn-outline text-secondary hover:bg-primary hover:text-white top-0 right-0 "
          onClick={onRemoveFileButtonClick}
        >
          <FaTrash></FaTrash>
        </button>
      )}
    </div>
  );

  return (
    <div
      className={isFileBeingDragged ? className : defaultClassName}
      onDragEnter={onFileDragEnter}
      onDragOver={onFileDragOver}
      onDrop={onFileDrop}
    >
      {$inputBlock}
      {$fileBlock}
    </div>
  );
};
