import { useAuth } from "@contexts/ProvideAuth";
import React from "react";
import { FaPowerOff, FaUser } from "react-icons/fa";
import LoginFormModal from "./LoginFormModal";

const Navbar = () => {
  const { user, signout } = useAuth();

  const onLogoutButtonClick = () => {
    signout();
  };
  return (
    <div className="navbar bg-[#03242a] flex-row items-center justify-center">
      <div className="flex-1 text-[#fff]">
        <a
          className="btn btn-ghost normal-case text-xl"
          target="_blank"
          href="https://www.hubi.ai"
        >
          <img
            className="h-[50px]"
            src="../../../img/logo-hubi.svg"
            alt="hubi logo"
          />
        </a>
      </div>
      {user && (
        <div className="flex-none">
          <div className="dropdown dropdown-hover dropdown-left">
            <label tabIndex={0}>
              <span className="h-[60px] w-[60px] border-2 border-primary rounded-full p-3 flex items-center justify-center">
                <img src="../../../img/logo-pouple-hubi.png" alt="hubi logo" />
              </span>
            </label>
            <ul
              tabIndex={0}
              className="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-52"
            >
              <li onClick={() => onLogoutButtonClick()}>
                <div>
                  <p className="flex items-center justify-center">
                    <FaPowerOff className="mr-2" /> Déconnexion
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      )}
      {!user && (
        <div className="flex-none">
          <label
            htmlFor="createScriptModal"
            className="btn btn-outline btn-secondary"
          >
            Connexion
          </label>
          <LoginFormModal modalId="createScriptModal" />
        </div>
      )}
    </div>
  );
};

export default Navbar;
