import { getEntities } from "@libs/azureDataTables";
import { Tool } from "@models/tool.model";

export const getTools = async () => {
  const result = await getEntities("tools");
  const tools = [];

  for await (const entity of result) {
    const tool: Tool = entity;
    tools.push(tool);
  }
  return tools;
};
