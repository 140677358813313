import React, { useState, useEffect, useContext, createContext } from "react";
import axios from "axios";
import jwt_decode from "jwt-decode";
import { User } from "@models/user.model";

export const useProvideAuth = () => {
  const [user, setUser] = useState<User>();
  const [expire, setExpire] = useState<number>(0);
  const [refreshToken, setRefreshToken] = useState(null);

  const trustedClients = process.env.REACT_APP_HUBI_ALLOWED_CLIENTS?.split(",");

  const signin = async (identifier: any): Promise<User | void> => {
    const response = await axios.post(
      `${process.env.REACT_APP_HUBI_API_ROOT_URL}/api/Login`,
      identifier
    );
    const token = response.data.token;
    localStorage.setItem("hubi-gallery-token", JSON.stringify(token));
    const decoded: any = jwt_decode(token);
    return authenticate(decoded);
  };
  const signout = () => {
    setExpire(0);
    setRefreshToken(null);
    setUser(undefined);
    localStorage.removeItem("hubi-gallery-token");
  };
  const checkToken = () => {
    const token = localStorage.getItem("hubi-gallery-token");
    if (!token) {
      return;
    }
    const decoded: any = jwt_decode(token);
    if (new Date(decoded.exp * 1000) < new Date()) {
      signout();
      return;
    }
    return authenticate(decoded);
  };

  const authenticate = (decodedToken: any) => {
    if (
      trustedClients &&
      !trustedClients.some((tc) => tc === decodedToken.clientId)
    ) {
      return;
    }
    const user: User = {
      id: decodedToken.userId,
      role: decodedToken.role,
      roleId: decodedToken.roleId,
      email: decodedToken.email,
      clientId: decodedToken.clientId,
      firstName: decodedToken.firstName,
      organisationId: decodedToken.organisationId,
      organisationName: decodedToken.organisationName,
      lastName: decodedToken.lastName,
      language: decodedToken.languages,
      phone: decodedToken.phone,
    };
    const expire = decodedToken.exp * 1000;
    setExpire(expire);
    setUser(user);
    const sessionTimeout = expire - new Date().getTime();
    setTimeout(() => {
      signout();
    }, sessionTimeout);
    return user;
  };
  useEffect(() => {
    checkToken();
  }, []);

  return {
    user,
    signin,
    signout,
  };
};
