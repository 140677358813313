import { getEntities } from "@libs/azureDataTables";
import { Customer } from "@models/customer.model";

export const getCustomers = async () => {
  const result = await getEntities("customers");
  const customers = [];

  for await (const entity of result) {
    const customer: Customer = entity;
    customers.push(customer);
  }
  return customers;
};
